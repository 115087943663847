<template>
  <div>
    <div class="d-flex align-items-baseline">
      <label for="">{{title}}</label>
      <div class="ml-2" v-for="(variable, index) in variables" :key="index">
        <b-button
          @click="copy_to_clipboard(variable)"
          variant="outline-dark"
          :id="`button-tag-popover-${element}${index}`"
        >
          {{ variable }}
        </b-button>
        <b-popover
          :target="`button-tag-popover-${element}${index}`"
          triggers="hover"
          placement="right"
          variant="info"
        >
          <div
            class="p-3"
            v-html="
              `Você pode usar esta tag adicionando <br> {{ ${variable} }} <br>ao editor ou clicando nesse botão e colando no editor`
            "
          ></div>
        </b-popover>
      </div>
    </div>    
  </div>
</template>

<script>
export default {
  props: {
    variables: Array,
    element: String,
    title:String
  },
  methods: {
    copy_to_clipboard(variable) {
      navigator.clipboard.writeText(`{{ ${variable} }}`);
    },
  },
};
</script>

<style>
</style>